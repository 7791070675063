import './styles.css';
import { Outlet } from 'react-router-dom';

const ForArtist2 = () => {


  return (  <Outlet />  )

}

export default ForArtist2;






